<template>
  <div class="stickyHeadBar">
    <!-- <div class="announcementBar">
      <div class="db-container">
        <p><b>Important Announcement:</b> Meriwebshop.com is Now YeetCommerce.com!</p>
        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. <a href="#.">Update Theme</a></p>
        <button>
          <svg enable-background="new 0 0 32 32" id="Слой_1" version="1.1" viewBox="0 0 32 32" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <path d="M17.459,16.014l8.239-8.194c0.395-0.391,0.395-1.024,0-1.414c-0.394-0.391-1.034-0.391-1.428,0  l-8.232,8.187L7.73,6.284c-0.394-0.395-1.034-0.395-1.428,0c-0.394,0.396-0.394,1.037,0,1.432l8.302,8.303l-8.332,8.286  c-0.394,0.391-0.394,1.024,0,1.414c0.394,0.391,1.034,0.391,1.428,0l8.325-8.279l8.275,8.276c0.394,0.395,1.034,0.395,1.428,0  c0.394-0.396,0.394-1.037,0-1.432L17.459,16.014z" id="Close"/>
            <g/><g/><g/><g/><g/><g/>
          </svg>
        </button>
      </div>
    </div> -->

    <!-- announcements starts here -->
      <Announcements ref="announcements"/>
    <!-- announcements ends here -->

    <div class="db-blue-section responsivePd">
      <div class="db-container">
        <div class="row">
          <div class="col-md-12 blue-section-content">
            
            <div class="mobie-nav-h opensideBarDashboard">
              <a href="#." @click.prevent="showSideBarDb" class="openSideBarBtn">
                <svg fill="#fff" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                  <title />
                  <g data-name="menu " id="menu_">
                    <path d="M29,6H3A1,1,0,0,0,3,8H29a1,1,0,0,0,0-2Z" />
                    <path d="M3,17H16a1,1,0,0,0,0-2H3a1,1,0,0,0,0,2Z" />
                    <path d="M25,24H3a1,1,0,0,0,0,2H25a1,1,0,0,0,0-2Z" />
                  </g>
                </svg>
              </a>
              <div class="pageItemTitle" v-if="settingsLoader">
                <vue-skeleton-loader
                  type="rect"
                  :width="210"
                  :height="44"
                  animation="fade"
                />
              </div>
              <div class="pageItemTitle" id="component-name" v-else>
                <div 
                  class="tourBtn" 
                  id="tour-start-btn" 
                  data-toggle="tooltip" 
                  data-placement="bottom" 
                  title="Start Tour"
                  v-if="pageName != 'EDIT SHIPPING REGION' &&
                  pageName != 'EDIT BILL' &&
                  pageName != 'SETTINGS' && 
                  pageName != 'HISTORY' &&
                  pageName != 'PROFILE' &&
                  pageName != 'HELP' &&
                  pageName != 'GALLERY' &&
                  pageName != 'THEMES' &&
                  pageName != 'FACEBOOK SHOP' &&
                  pageName != 'GOOGLE SHOP' &&
                  pageName != 'SOCIAL MEDIA'"
                >
                  <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" @click="startTour()">
                    <path d="M12 8V12M12 16H12.01M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  </svg>  
                </div>

                <h2>{{ pageName }}</h2>
                <div class="addNewProductBtn">
                  <button 
                    @click="createNew()" 
                    id="add-new-record" 
                    :disabled="getActiveTour()"
                    v-if="pageName != 'DASHBOARD' && 
                    pageName != 'PROFILE' &&
                    pageName != 'PAYMENT METHODS' && 
                    pageName != 'GENERAL' && 
                    pageName != 'REDIRECTS' &&
                    pageName != 'PAGE DETAILS' &&
                    pageName != 'ROBOTS' &&
                    pageName != 'SEARCH APPEARANCE' &&
                    pageName != 'THEME CUSTOMIZATION' &&
                    pageName != 'THEME BANNERS' &&
                    pageName != 'GALLERY' &&
                    pageName != 'FAQs' &&
                    pageName != 'SETTINGS' &&
                    pageName != 'DOMAIN SETUP' &&
                    pageName != 'SUBSCRIPTION' &&
                    pageName != 'PRIVACY POLICY' &&
                    pageName != 'TERMS & CONDITIONS' &&
                    pageName != 'THEMES' &&
                    pageName != 'ADD BILL' &&
                    pageName != 'EDIT BILL' &&
                    pageName != 'HEADER NAVIGATION' &&
                    pageName != 'FOOTER NAVIGATION' && 
                    pageName != 'ADD SHIPPING REGION' &&
                    pageName != 'EDIT SHIPPING REGION' &&
                    pageName != 'HELP' &&
                    pageName != 'SMTP' &&
                    pageName != 'PIXEL MANAGEMENT' &&
                    pageName != 'FACEBOOK SHOP' &&
                    pageName != 'GOOGLE SHOP' &&
                    pageName != 'SOCIAL MEDIA' &&
                    pageName != 'MARKETING TOOLS' &&
                    pageName != 'DROP SHIPPING'"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.144 18.141">
                      <g id="Group_24769" data-name="Group 24769" transform="translate(-0.174 -0.175)">
                        <path id="Path_51703" data-name="Path 51703" d="M18.144,9.284a3.3,3.3,0,0,1-.127.4,1.556,1.556,0,0,1-1.509.977c-1.872,0-3.745,0-5.617,0h-.224v.225c0,1.878,0,3.756,0,5.634a1.593,1.593,0,1,1-3.187.008c0-1.878,0-3.756,0-5.634v-.233H7.261q-2.817,0-5.635,0A1.593,1.593,0,1,1,1.6,7.478c1.347-.006,2.693,0,4.04,0H7.477V7.252q0-2.746,0-5.492A1.6,1.6,0,0,1,8.828.018.132.132,0,0,0,8.859,0h.425a.128.128,0,0,0,.03.018A1.6,1.6,0,0,1,10.666,1.76q0,2.746,0,5.492v.225h5.842a1.528,1.528,0,0,1,1.363.717,3.841,3.841,0,0,1,.273.665Z" transform="translate(0.174 0.175)"/>
                      </g>
                    </svg>
                  </button>
                  <button 
                    @click="loadData()" 
                    id="reload-data-btn" 
                    :disabled="getActiveTour()"
                    v-if="pageName == 'DASHBOARD' ||
                    pageName == 'ORDERS' ||
                    pageName == 'PRODUCTS' || 
                    pageName == 'CUSTOMERS'"
                  >
                    <el-tooltip class="box-item" effect="dark" content="Click to pull in fresh data" placement="bottom-start">
                      <svg height="13px" id="Layer_1" style="enable-background:new 0 0 32 32;" version="1.1" viewBox="0 0 32 32" width="13px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <path d="M28,16c-1.219,0-1.797,0.859-2,1.766C25.269,21.03,22.167,26,16,26c-5.523,0-10-4.478-10-10S10.477,6,16,6  c2.24,0,4.295,0.753,5.96,2H20c-1.104,0-2,0.896-2,2s0.896,2,2,2h6c1.104,0,2-0.896,2-2V4c0-1.104-0.896-2-2-2s-2,0.896-2,2v0.518  C21.733,2.932,18.977,2,16,2C8.268,2,2,8.268,2,16s6.268,14,14,14c9.979,0,14-9.5,14-11.875C30,16.672,28.938,16,28,16z"/>
                      </svg>
                    </el-tooltip>
                  </button>
                </div>
              </div>
            </div>
            <!-- mobile-view Dashboard end-->

            <div class="blue-right-content">
              <div class="mobile-resp-icons subProFileBox">
                <ul v-if="settingsLoader">
                  <li>
                    <div class="searchBlock"> 
                      <vue-skeleton-loader
                        type="rect"
                        :width="383"
                        :height="44"
                        animation="fade"
                      />
                    </div>
                  </li>
                  <li>
                    <div>
                      <vue-skeleton-loader
                        type="rect"
                        :width="40"
                        :height="44"
                        animation="fade"
                      />
                    </div>
                  </li>
                  <li>
                    <vue-skeleton-loader
                      type="rect"
                      :width="40"
                      :height="44"
                      animation="fade"
                    />
                  </li>
                  <li>
                    <vue-skeleton-loader
                      type="rect"
                      :width="157"
                      :height="44"
                      animation="fade"
                    />
                  </li>
                </ul>
                <ul v-else>
                  <li>
                    <div class="searchBlock" id="master-search">
                      <form @submit.prevent="">
                        <el-select
                          filterable
                          remote
                          reserve-keyword
                          placeholder="Search"
                          loading-text="Searching..."
                          no-data-text="Nothing found against the searched query."
                          @visible-change="changeHandle"
                          v-model="searchedItem"
                          :remote-method="asyncFind"
                          :fit-input-width="false"
                          :disabled="getActiveTour()"
                          :loading="isLoading"
                        >
                          <el-option-group v-for="(result,index) in results" :key="index" :label="result.module">
                            <el-option 
                              v-for="(item,i) in result.names" 
                              :key="i" 
                              :label="item.name.length > 50 ? `${item.name.substr(0, 50)}...` : item.name" 
                              :value="item"
                            >
                            </el-option>
                            <el-option 
                              v-if="result.names.length > 1 && result.module != 'Modules' &&
                              result.module != 'Payment Methods' && result.module != 'Pixel Management Tools' &&
                              result.module != 'Social Media Marketing Tools' && result.module != 'Dropshipping Platforms' && 
                              result.module != 'SMTPs'" 
                              :label="`Search '${searchedKeyword}' in ${result.module}`" 
                              :value="getValue(result.names[0])"
                            >
                            </el-option>
                          </el-option-group>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.491 17.49" slot="prefix">
                            <path id="Path_10" data-name="Path 10" d="M16,17.49h0L11,12.5v-.79l-.27-.28a6.512,6.512,0,1,1,.7-.7l.28.27h.79l4.99,5L16,17.489ZM6.5,2A4.5,4.5,0,1,0,11,6.5,4.505,4.505,0,0,0,6.5,2Z" />
                          </svg>
                        </el-select>
                      </form>
                    </div>
                  </li>
                  <li>
                    <button class="addListBtn" id="add-new-dropDown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :disabled="getActiveTour()">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.144 18.141">
                        <g id="Group_24769" data-name="Group 24769" transform="translate(-0.174 -0.175)">
                          <path id="Path_51703" data-name="Path 51703" d="M18.144,9.284a3.3,3.3,0,0,1-.127.4,1.556,1.556,0,0,1-1.509.977c-1.872,0-3.745,0-5.617,0h-.224v.225c0,1.878,0,3.756,0,5.634a1.593,1.593,0,1,1-3.187.008c0-1.878,0-3.756,0-5.634v-.233H7.261q-2.817,0-5.635,0A1.593,1.593,0,1,1,1.6,7.478c1.347-.006,2.693,0,4.04,0H7.477V7.252q0-2.746,0-5.492A1.6,1.6,0,0,1,8.828.018.132.132,0,0,0,8.859,0h.425a.128.128,0,0,0,.03.018A1.6,1.6,0,0,1,10.666,1.76q0,2.746,0,5.492v.225h5.842a1.528,1.528,0,0,1,1.363.717,3.841,3.841,0,0,1,.273.665Z" transform="translate(0.174 0.175)" fill="#fff"/>
                        </g>
                      </svg>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="add-new-dropDown">
                      <button class="dropdown-item" @click.prevent="() => $parent.openForm()" v-if="pageName == 'PRODUCTS'">
                        Add New Product
                      </button>
                      <button  class="dropdown-item" @click.prevent="$router.push({ name: 'Products' , params:{isCreateNew: true} }).catch(() => {});" v-else>
                        Add New Product
                      </button>
                      <button class="dropdown-item" @click.prevent="$parent.createOrder()" v-if="pageName == 'ORDERS'" >
                        Add New Order
                      </button>
                      <button class="dropdown-item" @click.prevent="$router.push({ name: 'Orders' , params:{isCreateNew: true} }).catch(() => {});" v-else>
                        Add New Order
                      </button>
                      <button class="dropdown-item" @click.prevent=" $parent.createCategory()" v-if="pageName == 'CATEGORIES'" >
                        Add New Category
                      </button>
                      <button class="dropdown-item" @click.prevent="$router.push({ name: 'Categories' , params:{isCreateNew: true} }).catch(() => {});" v-else>
                        Add New Category
                      </button>
                    </div>
                  </li>
                  <li>
                    <button class="helpIconBtn" @click.prevent="helpRedirect()" id="help-link" :disabled="getActiveTour()">
                      <svg id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g>
                          <path d="M345.1,77.1C317.6,56.2,286.6,49,247.3,49c-29.8,0-55.3,6.1-75.5,19.7C142,89,128,123.1,128,177h76.8   c0-14.4-1.4-29.9,7-43.2c8.4-13.3,20.1-23.5,40.2-23.5c20.4,0,30.9,5.9,40.8,18.1c8.4,10.4,11.6,22.8,11.6,36   c0,11.4-5.8,21.9-12.7,31.4c-3.8,5.6-8.8,10.6-15.1,15.4c0,0-41.5,24.7-56.1,48.1c-10.9,17.4-14.8,39.2-15.7,65.3   c-0.1,1.9,0.6,5.8,7.2,5.8c6.5,0,56,0,61.8,0c5.8,0,7-4.4,7.1-6.2c0.4-9.5,1.6-24.1,3.3-29.6c3.3-10.4,9.7-19.5,19.7-27.3   l20.7-14.3c18.7-14.6,33.6-26.5,40.2-35.9c11.3-15.4,19.2-34.4,19.2-56.9C384,123.5,370.5,96.4,345.1,77.1z M242,370.2   c-25.9-0.8-47.3,17.2-48.2,45.3c-0.8,28.2,19.5,46.7,45.5,47.5c27,0.8,47.9-16.6,48.7-44.7C288.8,390.2,269,371,242,370.2z"/>
                        </g>
                      </svg>
                    </button>
                  </li>
                  <li class="dropdown">
                    <a href="#." id="user-options-dropdown" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" :class="!getActiveTour() ? '': 'disabled'" :style="getActiveTour() ? 'cursor:auto;':''">
                      <div class="subHeaderProfileImg">
                        <img 
                          :src=" $store.state.user && storeAdmin.image ? base_image_url + settings.store_id + '/user/' + storeAdmin.image : '/images/user-avatar-filled.svg' "
                        />
                      </div>
                      <p>
                        {{ storeAdmin ? storeAdmin.first_name ? storeAdmin.last_name ? storeAdmin.first_name + " " + storeAdmin.last_name : storeAdmin.first_name : "--" : "--" }}
                      </p>
                      <span class="arrowIconbox">
                        <svg xmlns="http://www.w3.org/2000/svg" width="10.724" height="5.362" viewBox="0 0 10.724 5.362">
                          <path id="dropDownArrow" d="M1873,51l5,5,5-5Z" transform="translate(-1872.638 -50.85)" stroke="#000" stroke-width="0.3"/>
                        </svg>
                      </span>
                    </a>
                    <div class="dropdown-menu" aria-labelledby="user-options-dropdown">
                      <button class="dropdown-item" @click.prevent="dropDownClickHandle('profile')">
                        Profile
                      </button>
                      <button class="dropdown-item" @click.prevent="dropDownClickHandle('settings')">
                        Settings
                      </button>
                      <button class="dropdown-item" @click.prevent="$store.dispatch('logout');">
                        Logout
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import { MessageBox, Message } from 'element-ui'
import loaderBtn from "@/components/loaderBtn.vue";

import Announcements from "@/components/Announcements/index.vue";

export default {
  name:'subHeader',
  data: () => ({
    base_image_url: process.env.VUE_APP_STORE_IMAGE_BASE_URL,
    searchedItem: '',
    searchedKeyword:'',
    results:[],
    timer: undefined,
    isLoading:false,
    storeAdmin:{
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      mobile_no: '',
      city:'',
      address_line_1: '',
      address_line_2: '',
      image: null,
    },

  }),
  props: ["pageName"],
  components:{
    loaderBtn,
    Announcements
  },
  computed: {
    ...mapGetters({
      settings: "settings_module/settings",
      settingsLoader: "settings_module/isPending",
      logoutIsPending:'logoutIsPending',
      isAnnouncementPopupClosed:'settings_module/announcementPopupStatus',
    }), 
  },
  watch:{
    searchedItem:{
      handler:function(value){

        let selectedDropDownItem = value

        let option = null;

        this.results.forEach(result => {

          let found = result.names.find(x=> x.name == selectedDropDownItem.name && x.slug == selectedDropDownItem.slug);

          if(found){

            option = result;
            option.names = [];

            option.names.push(found);

            return

          }

        });

        if(option){

          if(option.module != "Modules"){
            
            if(option.module == "Orders"){
              
              if(this.$route.path != option.names[0].slug){

                selectedDropDownItem.name = selectedDropDownItem.name.split(' - ')[0];

                let payload = {
                  keyword: selectedDropDownItem.name,
                };
                
                this.$store.commit("orders_module/update_searched_keyword", payload);

                this.$router.push({path: option.names[0].slug , query:{search: selectedDropDownItem.name, preview: 1}});

              }else{
                
                selectedDropDownItem.name = selectedDropDownItem.name.split(' - ')[0];

                this.internalSearchHandle(selectedDropDownItem);

              }

            }else{

              if((option.module == 'Payment Methods') || (option.module == 'Pixel Management Tools') ||
              (option.module == 'Social Media Marketing Tools') || (option.module == 'Dropshipping Platforms') ||
              (option.module == 'SMTPs')){

                if(this.$route.path != option.names[0].slug){

                  this.$router.push({path: option.names[0].slug});

                }

              }else{

                if(this.$route.path != option.names[0].slug){

                  this.$router.push({path: option.names[0].slug , query:{search: selectedDropDownItem.name, preview: 1}});

                }else{

                  this.internalSearchHandle(selectedDropDownItem);

                }

              }

            }
            
          }else{

            if(this.$route.path != option.names[0].slug){

              this.$router.push({path: option.names[0].slug});

            }

          }

        }else if(value.searchInModule){

          if(this.$route.path != value.slug){

            this.$router.push({path: value.slug , query:{search: value.name, preview: 0}});

          }else{

            this.internalSearchHandle(value);

          }

        }

      },deep:true
    },
    settings:{
      handler:function(value){

        this.storeAdmin = _.cloneDeep(value.admin);

      },deep:true
    }
  },
  methods: {
    async asyncFind(query){

      if(query.trim().length > 0){
        
        this.searchedKeyword = query.trim();

        let sortByModule = null;

        if(this.isUpdatedUIModule()){

          sortByModule = this.pageName;

        }

        this.isLoading = true;

        clearTimeout(this.timer);

        this.timer = setTimeout(() => {

          this.$axios.get(`/dashboard/search/all`,{
            params:{
              keyword: query.trim(),
              sortBy: sortByModule
            }
          }).then(resp =>{

            if(resp.data.result.length > 0){
              
              this.results = _.cloneDeep(resp.data.result);
            
            }else{

              this.results = [];

            }

            this.isLoading = false;

          })
        },500)

      }else{

        this.results = [];

      }

    },
    changeHandle(dropDownIsClose){

      if(!dropDownIsClose){

        this.results = [];
        this.searchedItem = '';

      }
      
    },
    getValue(result){

      let tempValue = {
        name: this.searchedKeyword,
        slug: result.slug,
        searchInModule: 1,
      }

      return tempValue;

    },
    dropDownClickHandle(option){

      if(option == 'profile'){

        if(this.$route.path != '/settings/profile'){

          this.$router.push({ path: '/settings/profile' });

        }

      }else if(option == 'settings'){


        if(this.$route.path != '/settings/store-settings'){

          this.$router.push({ path: '/settings/store-settings' });

        }

      }

    },
    showSideBarDb(){

      var element = document.getElementsByTagName("body")[0];

      if(element.classList.contains('hideSideBar')){

        element.classList.remove("hideSideBar");
        element.classList.add("activeIpad");

      }else if(element.classList.contains('activeIpad')){

        element.classList.remove("activeIpad");
        element.classList.add("hideSideBar");

      }

    },
    internalSearchHandle(option){

      if(this.pageName == 'ORDERS'){

        this.$parent.filters.searchedKeyword = option.name;
        this.$parent.filters.statuses = [];

        if(option.searchInModule && option.searchInModule == 1){

          this.$parent.filterOrdersInternalSearch(false);

        }else{

          this.$parent.filterOrdersInternalSearch(true);

        }

      }
      if(this.pageName == "CATEGORIES"){

        if(!option.searchInModule){

          this.$parent.isMasterSearched = true;

        }

        this.$parent.searchCategory(option.name);

      }
      if(this.pageName == 'BRANDS'){

        if(!option.searchInModule){

          this.$parent.isMasterSearched = true;

        }

        this.$parent.searchBrand(option.name);

      }
      if(this.pageName == 'PRODUCTS'){

        this.$parent.filter.searchedTerm = option.name;

        this.$parent.filter.categories = [];
        this.$parent.filter.category_ids = [];
        this.$parent.filter.type = [];
        this.$parent.filter.status = [];
        this.$parent.filter.brands = [];
        this.$parent.filter.brand_ids = [];
        this.$parent.filter.tags = [];

        this.$parent.allFilters = [];

        if(!option.searchInModule){
        
          this.$parent.isMasterSearched = true;
        
        }

        this.$parent.searchByName();

      }
      if(this.pageName == 'PRODUCT BUNDLES'){

        this.$parent.filter.searchedTerm = option.name;

        this.$parent.filter.status = [];
        this.$parent.filter.tags = [];

        this.$parent.allFilters = [];

        if(!option.searchInModule){

          this.$parent.isMasterSearched = true;

        }

        this.$parent.searchProductBundle();

      }
      if(this.pageName == "CUSTOMERS"){

        if(!option.searchInModule){

          this.$parent.isMasterSearched = true;

        }

        this.$parent.searchCustomer(option.name);

      }
      if(this.pageName == 'SHIPPING REGIONS'){

        this.$parent.searchShippingRegion(option.name);

      }
      if(this.pageName == 'COUPONS'){

        if(!option.searchInModule){

          this.$parent.isMasterSearched = true;

        }

        this.$parent.searchCoupon(option.name);

      }
      if(this.pageName == "PAGE DETAILS"){

        if(!option.searchInModule){

          this.$parent.isMasterSearched = true;

        }

        this.$parent.searchPageDetail(option.name);

      }
      if(this.pageName == "NO INDEX URLS"){

        this.$parent.searchedItem = option.name;

        if(!option.searchInModule){

          this.$parent.isMasterSearched = true;

        }

        this.$parent.searchNoIndexUrl();
        
      }
      if(this.pageName == "BLOG CATEGORIES"){

        if(!option.searchInModule){

          this.$parent.isMasterSearched = true;

        }

        this.$parent.searchBlogCategory(option.name);

      }
      if(this.pageName == "BLOGS"){

        if(!option.searchInModule){

          this.$parent.isMasterSearched = true;

        }

        this.$parent.searchBlog(option.name);

      }
      if(this.pageName == "ACCOUNTS"){

        if(!option.searchInModule){

          this.$parent.isMasterSearched = true;

        }

        this.$parent.searchAccount(option.name);

      }
      if(this.pageName == "EXPENSES"){

        if(!option.searchInModule){

          this.$parent.isMasterSearched = true;

        }

        this.$parent.searchExpense(option.name);

      }
      if(this.pageName == "VENDORS"){

        if(!option.searchInModule){

          this.$parent.isMasterSearched = true;

        }

        this.$parent.searchVendor(option.name);

      }
      if(this.pageName == "BILLS"){

        this.$parent.searchBill(option.name);

      }
      if(this.pageName == "NOTIFICATIONS"){

        if(!option.searchInModule){

          this.$parent.isMasterSearched = true;

        }

        this.$parent.searchNotification(option.name);

      }
      if(this.pageName == "SUPPORT MEMBERS"){

        if(!option.searchInModule){

          this.$parent.isMasterSearched = true;

        }

        this.$parent.searchSupportMember(option.name);

      }
      if(this.pageName == "USER MANAGEMENT"){

        if(!option.searchInModule){

          this.$parent.isMasterSearched = true;

        }

        this.$parent.searchUser(option.name);

      }
      if(this.pageName == 'CONTACTS'){

        if(!option.searchInModule){

          this.$parent.isMasterSearched = true;

        }

        this.$parent.searchContact(option.name);

      }
      if(this.pageName == 'GROUPS'){

        if(!option.searchInModule){

          this.$parent.isMasterSearched = true;

        }

        this.$parent.searchGroup(option.name);

      }
      if(this.pageName == 'EMAILS'){

        if(!option.searchInModule){

          this.$parent.isMasterSearched = true;

        }

        this.$parent.searchEmail(option.name);

      }
      
    },
    helpRedirect(){

      if(!this.getActiveTour()){

        this.$router.push('/help');
        
      }

    },
    createNew(){

      if(!this.getActiveTour()){

        if(this.pageName == 'ORDERS'){

          this.$parent.createOrder();

        }
        if(this.pageName == "PRODUCTS"){

          this.$parent.openForm();

        }
        if(this.pageName == "PRODUCT BUNDLES"){

          this.$parent.createBundle();

        }
        if(this.pageName == "CATEGORIES"){

          this.$parent.createCategory();

        }
        if(this.pageName == "BRANDS"){

          this.$parent.createBrand();

        }
        if(this.pageName == "CUSTOMERS"){

          this.$parent.createCustomer();

        }
        if(this.pageName == "SHIPPING REGIONS"){

          this.$router.push('/e-commerce/shipping-region/add');

        }
        if(this.pageName == "COUPONS"){

          this.$parent.createCoupon();

        }
        if(this.pageName == "NO INDEX URLS"){

          this.$parent.createNoIndexUrl();

        }
        if(this.pageName == "BLOGS"){

          this.$parent.createBlog();

        }
        if(this.pageName == "BLOG CATEGORIES"){

          this.$parent.createBlogCategory();

        }
        if(this.pageName == "BILLS"){

          this.$router.push('/expenses-purchases/bill/add');

        }
        if(this.pageName == "VENDORS"){

          this.$parent.createVendor();

        }
        if(this.pageName == "EXPENSES"){

          this.$parent.createExpense();

        }
        if(this.pageName == "ACCOUNTS"){

          this.$parent.createAccount();

        }
        if(this.pageName == "NOTIFICATIONS"){

          this.$parent.createNotification();

        }
        if(this.pageName == "SUPPORT MEMBERS"){

          this.$parent.createSupportMember();

        }
        if(this.pageName == "USER MANAGEMENT"){

          this.$parent.createUser();

        }
        if(this.pageName == "CONTACTS"){

          this.$parent.createContact();

        }
        if(this.pageName == "GROUPS"){

          this.$parent.createGroup();

        }
        if(this.pageName == "EMAILS"){

          this.$parent.createEmail();
          
        }

      }

    },
    isUpdatedUIModule(){

      if(this.pageName == "ORDERS" || this.pageName == "PRODUCTS" || this.pageName == "PRODUCT BUNDLES" || 
      this.pageName == "CATEGORIES" || this.pageName == "BRANDS" || this.pageName == "CUSTOMERS" || 
      this.pageName == "SHIPPING REGIONS" || this.pageName == "COUPONS" || this.pageName == "PAGE DETAILS" || 
      this.pageName == "NO INDEX URLS" || this.pageName == "BLOG CATEGORIES" || this.pageName == "BLOGS" || 
      this.pageName == "BILLS" || this.pageName == "ACCOUNTS" || this.pageName == "EXPENSES" || 
      this.pageName == "VENDORS" || this.pageName == "NOTIFICATIONS" || this.pageName == "SUPPORT MEMBERS" || 
      this.pageName == "USER MANAGEMENT" || this.pageName == "CONTACTS" || this.pageName == "GROUPS" || 
      this.pageName == "EMAILS"){

        return true;

      }else{

        return false;

      }

    },

    //load module data starts here
    loadData(){

      MessageBox.confirm(
        "Do you really want to load the updated data? Unsaved changes will be discarded.", 
        "Warning", 
        {
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(async () => {

        if(this.pageName == 'DASHBOARD'){

          //loading dashboard stats
          this.$store.dispatch("dashboard_stats/fetchStatsRequest", 'today');

          //loading orders
          this.$parent.$refs.order_table.ordersLoading = true;

          this.$store.dispatch("orders_module/fetchStoreOrdersRequest",{size: 15, page: 1, loadData: 'new'});

          let payload = {
            days: 7,
            filter: 'week',
          }

          //loading customers vs visitors stats
          this.$store.dispatch('line_graph_module/fetchLineGraphRequest',payload);

          //loading sales vs expenses stats
          let storeYear = new Date(this.settings.created_at).getFullYear();

          let currentYear = new Date().getFullYear();

          let yearsList = []

          if(currentYear == storeYear){

            yearsList.push(currentYear);

          }else if(currentYear > storeYear) {

            for(let i = currentYear; i >= storeYear; i--){

              yearsList.push(i);

            }

          }

          this.$store.dispatch("bar_graph_module/fetchBarGraphRequest",yearsList[0]);

          //loading dashboard product stats
          this.$store.dispatch('dashboard_product_stats/fetchStatsRequest',{filter: 'topSelling',page: 1});

          //loading dashboard order stats
          this.$store.dispatch("dashboard_order_stats/fetchStatsRequest");

          //loading sales distribution stats
          let salesDistributionFilter = {
            filter: 'topCities',
          }

          this.$store.dispatch('pie_graph_module/fetchPieGraphRequest',salesDistributionFilter);

          //loading form summaries
          this.$parent.formsLoader = true;

          this.$store.dispatch('form_summary_module/fetchFormSummaryRequest',{size:10, page:1, loadData: 'new'});

          //loading notifications
          this.$parent.notificationsLoader = true;

          this.$store.dispatch('notifications_module/fetchStoreNotificationsRequest',{size:10, page: 1, loadData: 'new'});

        }else if(this.pageName == 'ORDERS'){

          this.$parent.filters = {
            searchedKeyword: '',
            statuses: [],
          }

          if(this.$parent.$refs.order_table.addOrderForm.isOpen){

            this.$parent.$refs.order_table.newOrderOperations('close','without-confirmation');

          }else if(this.$parent.$refs.order_table.updateOrderForm.isPreview){

            this.$parent.$refs.order_table.updateOrderOperations('close','without-confirmation');

          }

          this.$parent.$refs.order_table.ordersLoading = true;

          this.$store.commit("orders_module/remove_searched_keyword");

          this.$store.dispatch("orders_module/fetchStoreOrdersRequest",{size: 15, page: 1, loadData: 'new'});

          this.$parent.$refs.order_table.loadPaymentMethods();

          this.$parent.$refs.order_table.searchCartItems('products', 'simple', 1);

        }else if(this.pageName == 'PRODUCTS'){

          if(this.$parent.form.isOpen){

            this.$parent.closePreview('add');

          }else if(this.$parent.selectedOrder.isPreview){

            this.$parent.closePreview('edit');

          }

          this.$parent.filter = {
            categories: [],
            category_ids: [],
            type: [],
            status: [],
            brands: [],
            brand_ids: [],
            tags: [],
            searchedTerm: "",
          }

          this.$parent.allFilters = [];

          this.$parent.boxLoader = true;

          let filters = {
            categories: [],
            category_ids: [],
            type: [],
            status: [],
            brands: [],
            brand_ids: [],
            tags: [],
            searchedTerm: "",
          }

          this.$store.commit("products_module/update_product_filters",filters);
          this.$store.commit("products_module/update_product_allFilters",[]);

          this.$store.dispatch("products_module/fetchStoreProductsRequest",{ size: 15, page: 1, loadData: 'new'});

          this.$parent.loadProductFilterCategories('simple');

          this.$parent.loadProductFilterBrands('simple');

          this.$parent.loadProductFilterTags('simple');

          this.$parent.loadUpsellProducts(null);

          this.$parent.loadGoogleShopConnection();

        }else if(this.pageName == 'CUSTOMERS'){

          if(this.$parent.selectedCustomer.id){

            this.$parent.resetPreview();

          }

          this.$parent.$refs.module_search.searchedKeyword = "";

          this.$parent.boxIsLoading = true;

          let payload = {
            keyword: "",
          }

          this.$store.commit('customers_module/update_searched_keyword',payload)

          this.$store.dispatch('customers_module/fetchStoreCustomersRequest',{size: 15, page:1, loadData:'new'});

        }

        //loading settings
        this.$store.dispatch('settings_module/fetchStoreSetting');

        //loading main stats (module stats)
        this.$store.dispatch('main_stats/fetchStatsRequest');

      }).catch(() => {})

    },  
    //load module data ends here

    //tour
    startTour(){

      if(this.pageName == 'DASHBOARD'){

        this.$parent.startDashboardTour();

      }else{

        MessageBox.confirm(
          "Do you really want to initiate the module tour? unsaved changes will be discarded.", 
          "Warning", 
          {
            type: "warning",
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
          }
        ).then(()=>{

          //navigation menu modules starts here

            if(this.pageName == 'HEADER NAVIGATION'){

              this.$parent.startHeaderNavigationTour();

            }

            if(this.pageName == 'FOOTER NAVIGATION'){

              this.$parent.startFooterNavigationTour();

            }

          //navigation menu modules ends here

          //ecommerce menu modules starts here

            if(this.pageName == 'ORDERS'){

              this.$parent.startOrdersTour();

            }

            if(this.pageName == "PRODUCTS"){

              this.$parent.startProductsTour();

            }

            if(this.pageName == "PRODUCT BUNDLES"){

              this.$parent.startProductBundlesTour();

            }

            if(this.pageName == 'CATEGORIES'){

              this.$parent.startCategoryTour();

            }

            if(this.pageName == 'BRANDS'){

              this.$parent.startBrandTour();

            }

            if(this.pageName == 'CUSTOMERS'){

              this.$parent.startCustomersTour();

            }

            if(this.pageName == 'PAYMENT METHODS'){

              this.$parent.startPaymentModuleTour();

            }

            if(this.pageName == 'SHIPPING REGIONS'){

              this.$parent.startShippingTour();

            }

            if(this.pageName == 'ADD SHIPPING REGION'){

              this.$parent.$parent.startAddShippingTour();

            }

          //ecommerce menu modules ends here

          //promo & marketing menu modules starts here

            if(this.pageName == 'COUPONS'){

              this.$parent.startCouponsTour();

            }

            if(this.pageName == 'PIXEL MANAGEMENT'){

              this.$parent.startPixelManagementTour();

            }

            if(this.pageName == 'MARKETING TOOLS'){
        
              this.$parent.startMarketingToolsTour();

            }

            if(this.pageName == 'DROP SHIPPING'){

              this.$parent.startDropshippingTour();

            }

          //promo & marketing menu modules ends here

          //seo menu modules starts here

            if(this.pageName == 'GENERAL'){

              this.$parent.startseoGeneralsTour();

            }

            if(this.pageName == 'REDIRECTS'){

              this.$parent.startSeoRedirectsTour();

            }

            if(this.pageName == 'ROBOTS'){

              this.$parent.startSeoRobotTour();

            }

            if(this.pageName == 'PAGE DETAILS'){

              this.$parent.startPageDetailsTour();

            }

            if(this.pageName == 'NO INDEX URLS'){

              this.$parent.startNoIndexUrlsTour();

            }

            if(this.pageName == 'SEARCH APPEARANCE'){

              this.$parent.startSeoSearchAppearanceTour();

            }

          //seo menu modules ends here

          //content management menu modules starts here

            if(this.pageName == 'BLOG CATEGORIES'){

              this.$parent.startBlogCategoriesTour();

            }

            if(this.pageName == 'BLOGS'){

              this.$parent.startBlogsTour();

            }

            if(this.pageName == 'FAQs'){

              this.$parent.startFAQsTour();

            }

          //content management menu modules ends here

          //settings menu modules starts here

            if(this.pageName == "DOMAIN SETUP"){

              this.$parent.startDomainSetupTour();

            }

            if(this.pageName == 'SUBSCRIPTION'){

              this.$parent.startSubscriptionsTour();

            }

            if(this.pageName == "PRIVACY POLICY"){

              this.$parent.startPrivacyPolicyTour();

            }

            if(this.pageName == "TERMS & CONDITIONS"){

              this.$parent.startPrivacyPolicyTour();

            }  

          //settings menu modules ends here

          //theme management menu modules starts here

            if(this.pageName == "THEMES"){

              this.$parent.startThemeManagementTour();

            }

            if(this.pageName == 'THEME CUSTOMIZATION'){

              this.$parent.startContentBlocksTour();

            }

            if(this.pageName == 'THEME BANNERS'){

              this.$parent.startHomeBannerBlocksTour();

            }

          //theme management menu modules ends here

          //expenses/purchases menu modules starts here

            if(this.pageName == "ACCOUNTS"){

              this.$parent.startAccountsTour();

            }

            if(this.pageName == "EXPENSES"){

              this.$parent.startExpensesTour();

            }

            if(this.pageName == "VENDORS"){

              this.$parent.startVendorsTour();

            }

            if(this.pageName == 'BILLS'){

              this.$parent.startBillsTour();

            }

            if(this.pageName == 'ADD BILL'){

              this.$parent.startAddBillsTour();

            }

          //expenses/purchases menu modules ends here

          if(this.pageName == 'NOTIFICATIONS'){

            this.$parent.startNotificationsTour();

          }
          
          if(this.pageName == "SUPPORT MEMBERS"){

            this.$parent.startChatSupportTour();

          }

          if(this.pageName == "USER MANAGEMENT"){

            this.$parent.startUserManagementTour();

          }

          //crm menu modules starts here
          
            if(this.pageName == "CONTACTS"){

              this.$parent.startContactsTour();

            }

            if(this.pageName == "GROUPS"){

              this.$parent.startGroupsTour();

            }

            if(this.pageName == "EMAILS"){

              this.$parent.startEmailsTour();

            }

            if(this.pageName == 'SMTP'){

              this.$parent.startSmtpTour();

            }

          //crm menu modules ends here

        }).catch((error) => {})

      }

    },
    getActiveTour(){

      if(this.pageName == 'DASHBOARD' || this.pageName == "PRODUCTS" || 
      this.pageName == "PRODUCT BUNDLES" || this.pageName == 'CATEGORIES' || 
      this.pageName == 'BRANDS' || this.pageName == 'CUSTOMERS' || 
      this.pageName == 'ORDERS' || this.pageName == 'SHIPPING REGIONS' || 
      this.pageName == 'COUPONS' || this.pageName == 'MARKETING TOOLS' || 
      this.pageName == 'DROP SHIPPING' || this.pageName == 'PAGE DETAILS' || 
      this.pageName == 'NO INDEX URLS' || this.pageName == 'BLOG CATEGORIES' || 
      this.pageName == 'FAQs' || this.pageName == 'BLOGS' || 
      this.pageName == 'BILLS' || this.pageName == 'ADD BILL' || 
      this.pageName == 'VENDORS' || this.pageName == 'EXPENSES' || 
      this.pageName == "ACCOUNTS" || this.pageName == 'SUPPORT MEMBERS' || 
      this.pageName == 'USER MANAGEMENT' || this.pageName == 'SUBSCRIPTION' || 
      this.pageName == 'DOMAIN SETUP' || this.pageName == 'PRIVACY POLICY' || 
      this.pageName == 'TERMS & CONDITIONS' || this.pageName == 'NOTIFICATIONS' || 
      this.pageName == 'GENERAL' || this.pageName == 'REDIRECTS' || 
      this.pageName == 'ROBOTS' || this.pageName == 'SEARCH APPEARANCE' || 
      this.pageName == 'HEADER NAVIGATION' || this.pageName == 'FOOTER NAVIGATION' || 
      this.pageName == 'THEME CUSTOMIZATION' || this.pageName == 'THEME BANNERS' || 
      this.pageName == 'PAYMENT METHODS' || this.pageName == 'CONTACTS' || 
      this.pageName == "GROUPS" || this.pageName == "EMAILS" || 
      this.pageName == "SMTP" || this.pageName == "PIXEL MANAGEMENT"){

        return this.$parent.tourIsActive;

      }else if(this.pageName == 'ADD SHIPPING REGION'){

        if(this.$tours.addShippingTour){

          if(this.$tours.addShippingTour.currentStep != -1){

            return true;

          }else{

            return false;

          }

        }

      }

    },
  },
  mounted(){

    if((!this.settings || this.settings.length == 0 || this.settings.error) && !this.settingsLoader){

      this.$store.dispatch("settings_module/fetchStoreSetting");

    }else{

      this.storeAdmin = _.cloneDeep(this.settings.admin);

    }

  },
  beforeMount(){

  },
};
</script>

<style>
  .helpIconBtn{
    width: 40px;
    height: 40px;
    background-color: #1ec2c2;
    border-radius: 50%;
    border: none;
    padding: 7px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  }
  .helpIconBtn svg{
    fill: #fff;
    width: 100%;
    height: 100%;
  }
  .subHeaderProfileImg {
    width: 40px;
    height: 40px;
  }
  .subHeaderProfileImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .subProFileBox ul {
    margin-bottom: 0px !important;
  }
  .blue-right-content ul {
    align-items: center;
    display: flex;
    font-size: 20px;
  }
  .subProFileBox ul li {
    padding: 0px;
  }
  .subProFileBox ul li a p {
    font-size: 14px;
    color: #000000;
    margin-bottom: 0px;
    font-weight: 400;
    margin: 0px 12px;
  }
  .subProFileBox ul li a .arrowIconbox {
    display: inline-block;
    width: 10px;
    height: 5px;
  }
  .subProFileBox ul li a .arrowIconbox svg {
    width: 100%;
    height: 100%;
    display: block;
  }
  .subProFileBox ul li a {
    display: inline-flex;
    align-items: center;
  }
  .subProFileBox ul li button.addListBtn,
  .subProFileBox ul li a.addListBtn {
    width: 40px;
    height: 40px;
    background-color: #fd4d5d;
    border-radius: 50%;
    border: none;
    padding: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  }
  .subProFileBox ul li button.addListBtn svg,
  .subProFileBox ul li a.addListBtn svg {
    width: 100%;
    height: 100%;
  }
  .searchBlock {
    width: 383px;
    margin-right: 15px;
  }
  .searchBlock form {
    position: relative;
  }
  .searchBlock form .searchIconWrap {
    width: 17.49px;
    height: 17.49px;
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translate(0%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .searchBlock form .searchIconWrap svg {
    width: 100%;
    height: 100%;
    display: inline-block;
  }
  .searchBlock form input {
    background-color: #fff;
    padding: 10px 55px 10px 45px;
    border: 1px solid #707070;
    font-size: 14px;
    color: #848484;
    width: 100%;
    border-radius: 40px;
    line-height: 18px;
  }
  .filterBtnWrap {
    background-color: #f8f8f8;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    position: absolute;
    top: 3px;
    right: 3px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .filterBtnWrap svg {
    width: 21px;
    height: 19px;
    fill: #15223d;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .filterBtnWrap:hover {
    background-color: #15223d;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .filterBtnWrap:hover svg {
    fill: #fff;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  /* el select styling */
  .el-select{
    width: 100%;
  }
  .el-input__prefix{
    display: flex;
    align-items: center;
    justify-content: center;
    left: 15px;
    width: 17.49px;
  }
  .el-input__prefix svg{
    fill:#848484;
    width: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .is-focus .el-input__prefix svg{
    fill: #409EFF;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .el-select-dropdown.el-popper{
    z-index: 99999999 !important;
  }
  .el-input__inner{
    padding-left: 45px !important;
  }
  /* .el-select .el-input .el-select__caret::before {
    content: "\e6e1";
    color: #848484;
  }
  .el-select .el-input.is-focus .el-select__caret {     
    transform: rotateZ(0deg);
  }
  .is-focus .el-input__suffix .el-select__caret::before{
    color: #409EFF;
  } */

  /* .swiper {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
  } */
  .popupConfirmation{
    width:600px;
  }
</style>
